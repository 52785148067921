import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import TechBanner from "../components/Tech/Banner"
import WhyChooseUs from '../components/AboutUs/WhyChooseUs'

const Tech = () => {
  return (
    <Layout>
      <Navbar />
      <TechBanner />
      <WhyChooseUs />
      <Footer />
    </Layout>
  )
}

export default Tech